<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="approvala">
        <div class="tit">
            <p class="fanhui" @click="fanhui">
                <img src="../assets/histry.png" alt="">
            </p>
            <p class="biaoti_mingzi">{{onezhi_a.shenpi_mingcheng}}</p>
            <ul>
                <li v-for="(i,index) in text" :key='index' @click="qiehuan(index)" :style="moren==index?'background:#8d52e3;':''"><p class="yuan" :style="moren==index?'background:#fff;border:0.01rem solid #fff;color:#8d52e3;':'border:0.01rem solid #fff;color:#fff;'">{{index+1}}</p>{{i}}<p class="fangkuai" v-if="moren==index"></p></li>
            </ul>
            <div>
                <p @click="fanhui">取消</p>
                <p @click="dianji_baocun">保存</p>
            </div>
        </div>
        <div class="con">
            <shezhi class="shezhia" @onezhi='onezhi' v-if="moren==0"></shezhi>
            <biaodan v-if="moren==1"></biaodan>
            <setting v-show="moren==2" ref="my_three"></setting>
            <gaoji class="shezhia" @fourzhi='fourzhi' v-if="moren==3"></gaoji>
        </div>
    </div>
</template>

<script>
import shezhi from './shezhi'
import biaodan from './biaodan'
import gaoji from './gaojishezhi'
import setting from '../views/approval/setting'
import { insert_cust_form, replace_flow_form_info_private, update_flow_action_info, insert_flow_info, insert_flow_action_info, insert_flow_form_info, insert_flow_action_str } from '../api/api'
export default {
  name: 'approvala',
  data () {
    return {
      text: ['基础设置', '表单设计', '流程设计', '高级设置'],
      moren: '0',
      onezhi_a: '',
      fourzhi_a: '',
      threezhi_a: ''
    }
  },
  components: { shezhi, biaodan, setting, gaoji },
  created () {

  },
  mounted () {
  },
  watch: {
  },
  methods: {
    qiehuan (index) {
      this.moren = index
      if (index == 2) {
        this.$refs.my_three.cunfang_tree()
      }
    },
    onezhi (one_zhi) {
      this.onezhi_a = one_zhi
    },
    fourzhi (four_zhi) {
      this.fourzhi_a = four_zhi
    },
    // 点击返回
    fanhui () {
      this.$router.push('/appsett')
    },
    // 保存
    dianji_baocun () {
      if (this.onezhi_a == '' || this.onezhi_a.shenpi_mingcheng == '') {
        this.moren = 0
        this.$message({
          message: '基础设置还未填写完整',
          type: 'warning'
        })
      } else {
        if (this.$bendi_naqu_cunchu('mlbb_one_shezhi', '暂时') != undefined) {
          this.onezhi_a = this.$bendi_naqu_cunchu('mlbb_one_shezhi', '暂时')
        }
        if (this.$bendi_naqu_cunchu('mlbb_four_shezhi', '暂时') != undefined) {
          this.fourzhi_a = this.$bendi_naqu_cunchu('mlbb_four_shezhi', '暂时')
        }
        if (this.$refs.my_three.saveSet() == 2) {
          this.moren = this.$refs.my_three.saveSet()
        } else {
          // this.$refs.my_three.saveSet()
          if (this.fourzhi_a == '' || this.fourzhi_a.shifou == '') {
            this.moren = 3
            this.$message({
              message: '高级设置还未填写完整',
              type: 'warning'
            })
          } else {
            const suiji = (this.$bendi_naqu_cunchu('mlbb_shenpi_zhengyi', '暂时') != undefined && this.$bendi_naqu_cunchu('mlbb_shenpi_zhengyi', '暂时').flow_id != undefined) ? this.$bendi_naqu_cunchu('mlbb_shenpi_zhengyi', '暂时').flow_id : this.$func.randomstring(10)
            console.log(suiji, 1111119236398123)
            const shifou = ''
            let yijian = ''
            let zidong_tongguo = ''
            let yijian_tianxie = ''
            let shei_keyi_guanli = ''
            let shenpi_shuoming = ''
            let shenpi_shuoming_name = ''
            const cunchu_data = this.$bendi_naqu_cunchu('mlbb_biaodan_sheji', '暂时') != undefined ? this.$bendi_naqu_cunchu('mlbb_biaodan_sheji', '暂时') : []
            let gongshi_baocuo = false
            const bitian_zhi = []
            for (let sort = 0; sort < cunchu_data.length; sort++) {
              cunchu_data[sort].sort = sort + ''
              console.log(cunchu_data[sort])
              if (cunchu_data[sort].components != undefined) {
                for (let nei_sort = 0; nei_sort < cunchu_data[sort].components.length; nei_sort++) {
                  cunchu_data[sort].components[nei_sort].sort = nei_sort
                }
              }
              if (cunchu_data[sort].componentName == 'calcform' && cunchu_data[sort].gongshi.length == 0) {
                gongshi_baocuo = true
              }
              if (cunchu_data[sort].defaultImportant) {
                bitian_zhi.push('11111')
              }
            }
            if (cunchu_data.length == 0 || bitian_zhi.length == 0) {
              this.moren = 1
              this.$message({
                message: '表单必须有一个控件为必填值',
                type: 'warning'
              })
            } else {
              if (!gongshi_baocuo) {
                // let chuanshu_list=''
                const liucheng_list = {
                  nodeConfig: [],
                  flowPermission: null,
                  tableId: null,
                  all_or_not: 0,
                  faqi_fanwei_name: '所有人'
                }
                if (this.$bendi_naqu_cunchu('mlbb_liucheng_shezhi', '暂时') != undefined) {
                  liucheng_list.nodeConfig = this.$bendi_naqu_cunchu('mlbb_liucheng_shezhi', '暂时')
                }
                liucheng_list.flowPermission = this.$bendi_naqu_cunchu('mlbb_liucheng_shezhi_flowPermission', '暂时')
                if (liucheng_list.flowPermission != undefined && liucheng_list.flowPermission.length != 0) {
                  liucheng_list.all_or_not = 1
                  liucheng_list.faqi_fanwei_name = liucheng_list.flowPermission.map(ite => ite.name).join(',')
                }
                console.log(liucheng_list)
                if (this.$bendi_naqu_cunchu('mlbb_liucheng_shezhi_tableId', '暂时') != undefined) {
                  liucheng_list.tableId = this.$bendi_naqu_cunchu('mlbb_liucheng_shezhi_tableId', '暂时')
                }
                if (this.onezhi_a.shei_keyi_guanli_id == '') {
                  shei_keyi_guanli = null
                  shenpi_shuoming_name = null
                } else {
                  shei_keyi_guanli = this.onezhi_a.shei_keyi_guanli_id
                  shenpi_shuoming_name = this.onezhi_a.shei_keyi_guanli
                }
                if (this.onezhi_a.shenpi_shuoming == '') {
                  shenpi_shuoming = null
                } else {
                  shenpi_shuoming = this.onezhi_a.shenpi_shuoming
                }
                if (this.fourzhi_a.shenpi_yijian == false) {
                  yijian = '0'
                } else if (this.fourzhi_a.shenpi_yijian == true) {
                  yijian = '1'
                }
                if (this.fourzhi_a.faqiren_zidong_tongguo == false) {
                  zidong_tongguo = '0'
                } else if (this.fourzhi_a.faqiren_zidong_tongguo == true) {
                  zidong_tongguo = '1'
                }
                if (this.fourzhi_a.yijian == '') {
                  yijian_tianxie = null
                } else {
                  yijian_tianxie = this.fourzhi_a.yijian
                }
                let table = ''
                if (suiji == 'VMCizuVOXb') {
                  table = 'invoice'
                } else if (suiji == 'He5ln0bdDO') {
                  table = 'received_cash'
                } else if (suiji == 'qIwxmsMTgv') {
                  table = 'contract'
                } else if (suiji == 'Eb3n7CKQzi') {
                  table = 's_dispatch'
                } else if (suiji == 'PLOQ9ku3Ip') {
                  table = 'funn'
                } else if (suiji == 'GBh7N2jMsi') {
                  table = 's_biz_trip'
                } else if (suiji == 'cvRyWGiUQH') {
                  table = 's_go_out'
                } else if (suiji == '7TvJHPyovM') {
                  table = 'customer'
                } else if (suiji == 'Z7VDOK9HGu') {
                  table = 's_visit_plan'
                } else if (suiji == '453y6pLD52') {
                  table = 's_visit_record'
                } else if (suiji == 'H49d4edc3T') {
                  table = 's_deliver'
                } else if (suiji == '5FotYgnu0Z') { // 供应商录入单
                  table = 'supply'
                } else if (suiji == 'u1SCMMoUwK') { // 采购发票单
                  table = 'purchase_invoice'
                } else if (suiji == 'vY9ubhTO89') { // 付款单
                  table = 'payment'
                } else if (suiji == 'Xsk2pLBS9a') { // 采购单
                  table = 'purchase'
                } else if (suiji == 'fZ0x5qedzn') { // 入库单
                  table = 'in_store'
                } else if (suiji == 'hXstgwxhzh') { // 出库单
                  table = 'out_store'
                } else if(suiji == 'AY3cli7BAb') { //vip充值单
                  table = 'mc_topup'
                } else if(suiji == 'lN0x9pYEsk') {
                  table = 'null'
                }
                console.log(JSON.stringify({
                  data: {
                    ent_id: this.$ent_id(),
                    flow_id: suiji,
                    flow_name: this.onezhi_a.shenpi_mingcheng,
                    flow_desc: shenpi_shuoming,
                    manager: shei_keyi_guanli,
                    manager_name: shenpi_shuoming_name,
                    distinct_flag: this.fourzhi_a.shifou, // 审批人quchong  1同一个审批人在流程中出现多次，仅保留第一个  2  同一个审批人仅在连续出现时自动去重 3 不自动去重
                    print_flag: Number(this.fourzhi_a.dayin_num) + 1 + '',
                    rel_table: table,
                    appr_auto: zidong_tongguo, // 发起人是否自动通过0不通过    1自动通过
                    appr_tips: yijian_tianxie,
                    appr_must_flag: yijian
                  }
                }))
                console.log(JSON.stringify(cunchu_data))
                if (table.length != 0) {
                  console.log(this.$ent_id, 11111)
                  insert_flow_info({
                    data: {
                      ent_id: this.$ent_id(),
                      flow_id: suiji,
                      flow_name: this.onezhi_a.shenpi_mingcheng,
                      flow_desc: shenpi_shuoming,
                      flow_group_id: this.onezhi_a.flow_group_id,
                      manager: shei_keyi_guanli,
                      manager_name: shenpi_shuoming_name,
                      distinct_flag: this.fourzhi_a.shifou,
                      print_flag: Number(this.fourzhi_a.dayin_num) + 1 + '',
                      rel_table: table,
                      appr_auto: zidong_tongguo,
                      appr_tips: yijian_tianxie,
                      appr_must_flag: yijian
                    }
                  }).then(res => {
                    console.log(res)
                    if (res.data.code == 200) {
                      if (this.$ent_id() == 'public') {
                        insert_flow_form_info({
                          data: {
                            ent_id: 'public',
                            flow_id: suiji,
                            form_str: JSON.stringify(cunchu_data)
                          }
                        }).then(ras => {
                          if (ras.data.code == 200) {
                            insert_flow_action_str({
                              data: {
                                ent_id: this.$ent_id(),
                                flow_id: suiji,
                                action_str: JSON.stringify(liucheng_list)
                              }
                            }).then(rds => {
                              if (rds.data.code == 200) {
                                alert('111')
                              } else if (rds.data.code == 500) {}
                            })
                          } else if (ras.data.code == 500) {}
                        })
                      } else {
                        replace_flow_form_info_private({
                          data: {
                            ent_id: this.$ent_id(),
                            flow_id: suiji,
                            list: JSON.stringify(cunchu_data)
                          }
                        }).then(ras => {
                          if (ras.data.code == 200) {
                            const shifou_neng_chuqu = []
                            shifou_neng_chuqu.push('111')
                            insert_flow_action_str({
                              data: {
                                ent_id: this.$ent_id(),
                                flow_id: suiji,
                                action_str: JSON.stringify(liucheng_list)
                              }
                            }).then(rds => {
                              if (rds.data.code == 200) {
                                shifou_neng_chuqu.push('111')
                                if (shifou_neng_chuqu.length == 2) {
                                  this.$message({
                                    message: '保存成功',
                                    type: 'success'
                                  })
                                  sessionStorage.removeItem('mlbb_one_shezhi')
                                  sessionStorage.removeItem('mlbb_four_shezhi')
                                  sessionStorage.removeItem('mlbb_shenpi_zhengyi')
                                  sessionStorage.removeItem('mlbb_biaodan_sheji')
                                  sessionStorage.removeItem('mlbb_liucheng_shezhi')
                                  sessionStorage.removeItem('mlbb_liucheng_shezhi_flowPermission')
                                  sessionStorage.removeItem('mlbb_liucheng_shezhi_tableId')
                                  sessionStorage.removeItem('mlbb_liucheng_shezhi_list')
                                  this.$router.push('/appsett')
                                }
                              } else if (rds.data.code == 500) { alert('小虎哥报错了') }
                            })
                          } else if (ras.data.code == 500) {
                            alert('提交报错')
                          }
                        })
                      }
                    } else if (res.data.code == 500) {}
                  })
                } else {
                  insert_cust_form({
                    data: {
                      ent_id: this.$ent_id(),
                      flow_id: suiji,
                      flow_name: this.onezhi_a.shenpi_mingcheng,
                      flow_desc: shenpi_shuoming,
                      flow_group_id: this.onezhi_a.flow_group_id,
                      logo_url: this.onezhi_a.img,
                      manager: shei_keyi_guanli,
                      manager_name: shenpi_shuoming_name,
                      distinct_flag: this.fourzhi_a.shifou,
                      print_flag: Number(this.fourzhi_a.dayin_num) + 1 + '',
                      appr_auto: zidong_tongguo,
                      appr_tips: yijian_tianxie,
                      appr_must_flag: yijian,
                      form_set: JSON.stringify(cunchu_data),
                      flow_set: JSON.stringify(liucheng_list)
                    }
                  }).then(res => {
                    if (res.data.code == 200) {
                      this.$message({
                        message: '保存成功',
                        type: 'success'
                      })
                      sessionStorage.removeItem('mlbb_one_shezhi')
                      sessionStorage.removeItem('mlbb_four_shezhi')
                      sessionStorage.removeItem('mlbb_shenpi_zhengyi')
                      sessionStorage.removeItem('mlbb_biaodan_sheji')
                      sessionStorage.removeItem('mlbb_liucheng_shezhi')
                      sessionStorage.removeItem('mlbb_liucheng_shezhi_flowPermission')
                      sessionStorage.removeItem('mlbb_liucheng_shezhi_tableId')
                      sessionStorage.removeItem('mlbb_liucheng_shezhi_list')
                      this.$router.push('/appsett')
                    } else if (res.data.code == 500) {}
                  })
                }
              } else {
                this.$message({
                  message: '公式未填写',
                  type: 'warning'
                })
                this.moren = 1
              }
            }

          }
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.v-modal{
  display: none!important;
}
.approvala {
    background:#F4F7FD;
    min-height:100vh;
    .tit{
        display: flex;
        // align-items: center;
        justify-content: space-between;
        background: #8d52e3;
        padding: 0 0.29rem;
        height:0.54rem;
        position: relative;
        .fanhui{
            height:100%;
            display: flex;
            align-items: center;
            cursor: pointer;
            img{
                height: 32.0.05rem;
                // width:20.0.05rem;
                padding-right:0.2rem;
                height:0.22rem;
                border-right:0.01rem solid #7538d1;
                margin-right: 0.1rem;
            }
            color:#fff;
        }
        .biaoti_mingzi{
            position: absolute;
            width:3rem;
            // border:1px solid red;
            line-height: 0.54rem;
            height:0.54rem;
            left:0.8rem;
            color:#fff;
            word-break: keep-all; /* 不换行 */
            white-space: nowrap; /* 不换行 */
            overflow: hidden; /* 内容超出宽度时隐藏超出部分的内容 */
            text-overflow: ellipsis;
        }
        ul{
            display: flex;
            align-items: center;
            height:0.54rem;
            margin-left: 1.4rem;
            li{
                height:100%;
                display: flex;
                align-items: center;
                padding: 0 0.59rem;
                font-size: 0.14rem;
                color:#fff;
                position: relative;
                cursor: pointer;
                .yuan{
                    height:0.18rem;
                    width:0.18rem;
                    border-radius: 50%;
                    font-size: 0.14rem;
                    line-height: 0.18rem;
                    text-align: center;
                    margin-right:0.06rem;
                }
                .fangkuai{
                    position: absolute;
                    height:0.14rem;
                    width:0.14rem;
                    transform:rotate(45deg);
                    -ms-transform:rotate(45deg); /* Internet Explorer */
                    -moz-transform:rotate(45deg); /* Firefox */
                    -webkit-transform:rotate(45deg); /* Safari 和 Chrome */
                    -o-transform:rotate(45deg); /* Opera */
                    background:#E7E8EB;
                    bottom: -0.07rem;
                    left:50%;
                    margin-left: -0.07rem;
                }
            }
        }
        div{
            display: flex;
            align-items: center;
            p{
                padding: 0.12rem 0.27rem;
                font-size: 0.14rem;
                cursor: pointer;
                border:0.01rem solid #fff;
                &:nth-child(1){
                    color:#fff;
                    margin-right:0.11rem;
                }
                &:nth-child(2){
                    color:#8d52e3;
                    background: #fff;
                }
            }
        }
    }
    .con{
        .shezhia{
            margin: 0 auto;
            margin-top: 0.39rem;
        }
    }
}
@media screen and (max-width: 1220px) {
  .approvala {
    width:1220px;
  }
}
</style>
